import React, {useEffect} from 'react';
import "./firstPage.scss";
import BackgroundImg from "../../assets/img/house-winter-1.jpeg";
import Badge from "../../assets/img/award-2025.jpg";

export type FirstPageProps = {
    handleImageLoaded: () => void;
}

const FirstPage = ({handleImageLoaded}: FirstPageProps) => {
    const [isImageLoaded, setIsImageLoaded] = React.useState(false);
    useEffect(() => {
        const img = new Image();

        img.src = BackgroundImg;

        img.onload = () => {

            //wait 2 seconds before calling handleImageLoaded
            setTimeout(() => {
                handleImageLoaded();
                setIsImageLoaded(true);
            }, 2000);
        }

        img.onerror = () => {
            console.error("Error loading image");
        };
    }, []);

    if(!isImageLoaded) {
        return null;
    }


    const sectionStyle = {
        backgroundImage: `url(${BackgroundImg})`,
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        position: 'relative' as 'relative',
    }

    return (
        <section
            id="hero"
            className="d-flex align-items-center justify-content-center"
            style={sectionStyle}
        >
            <img
                src={Badge}
                alt="Norda Loft nagroda Booking Award 10/10"
                className="award-badge"
            />

            <div className="container" data-aos="fade-up">
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div className="col-xl-6 col-lg-8">
                        <h1><span>NORDA LOFT Dębki</span></h1>
                        <h2>Morski klimat, bliskość przyrody, spokój.</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FirstPage;
import React, {useEffect, useState} from 'react';
import Calendar from 'react-calendar';
import {isSameDay} from 'date-fns';
import _ from 'lodash';

import 'react-calendar/dist/Calendar.css';
import './nordaCallendar.scss';

export type NordaCalendarProps = {
}

export default function NordaCalendar({}: NordaCalendarProps) {

    const [date, setDate] = useState<Date | null | [Date, Date]>(new Date());
    const [isMobile, setIsMobile] = useState(false);
    const [datesToAddClassTo, setDatesToAddClassTo] = useState<string[]>([]); // State to hold API data
    const [isLoading, setIsLoading] = useState(true);

    const API_BASE_URL = process.env.REACT_APP_API_URL || '';

    useEffect(() => {
        // console.log('datesToAddClassTo updated:', datesToAddClassTo);
    }, [datesToAddClassTo]); // ✅ Logs whenever API data updates

        // Handle window resize for responsive design
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 720);
        };

        // Set initial state and add event listener
        handleResize();
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Fetch dates from API on component mount
    useEffect(() => {
        const fetchDates = async () => {
            try {
                setIsLoading(true);

                const response = await fetch(`${API_BASE_URL}/api/booking-dates`); // Fetch data from API on local development
                if (!response.ok) {
                    throw new Error(`Failed to fetch booking dates: ${response.statusText}`);
                }
                const data = await response.json(); // Expecting JSON response

                //Only update state if new data is different
                setDatesToAddClassTo(prevDates =>
                    JSON.stringify(prevDates) !== JSON.stringify(data) ? data : prevDates
                );
            } catch (error) {
                // console.error('Error fetching booking dates:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDates() // Call the async function

        const interval = setInterval(fetchDates, 300000); // Refresh every 5 min

        return () => clearInterval(interval); // Cleanup interval on unmount

    }, [] // Empty dependency array to run once on component mount
    );

    function tileContent({date, view}: { date: Date, view: string }) {
        // Add class to tiles in month view only
        if (view === 'month') {

            // Check if a date React-Calendar wants to check is on the list of dates to add class to
            if (_.has(datesToAddClassTo, date.getMonth())) {
                return <p>---</p>;
            }
        }

        return null;
    }

    // Add a custom class to booked dates
    function tileClassName({date, view}: { date: Date, view: string }) {
        if (
            view === 'month' &&
            datesToAddClassTo.some(
                (dateString) => isSameDay(new Date(dateString), date)
            )
        ) {
            return 'noBookingTime';
        }
        return null;
    }

    return (
        <div className='d-flex justify-content-center' data-aos="fade-up" data-aos-delay="250">
            {isLoading ? (
                <p>Loading calendar...</p> // Display a loading message while fetching data
            ) : (
                <Calendar
                    onChange={(value) => setDate(date)}
                    value={date}
                    selectRange={false}
                    tileClassName={tileClassName}
                />
            )}
        </div>
    );
}

